<style lang="scss" scoped>
.component-root {
  padding-bottom: 0.14rem;
}
.district-scroller {
  margin: 0;
  padding: 0;
  list-style: none;
  // max-height: 1.28rem;
  margin-left: 0.09rem;
  margin-right: 0.05rem;
  overflow-x: hidden;
  @include clear();
  &::-webkit-scrollbar-thumb {
    /* 滚动条里面小方块 */
    background: $scroll-thumb;
    border-radius: 10px;
  }
  li {
    float: left;
    box-sizing: border-box;
    width: 25%;
    padding-left: 0.08rem;
    padding-right: 0.08rem;
    margin-top: 0.05rem;
    margin-bottom: 0.05rem;
    button {
      @include button();
      width: 100%;
      text-align: center;
      &.active {
        @include buttonBright();
        color: #fff !important;
      }
    }
  }
  @media only screen and (device-width: 375px) {
    // width: 0.8rem;
    li {
      float: left;
      width: 50%;
    }
  }
}
</style>

<template>
  <div class="component-root" style="padding-bottom: 0;">
    <!-- 地区选择 -->
    <div class="message-header">
      <em class="icon-dot"></em>报考地区选择
    </div>
    <ul class="district-scroller lyt-4-columns">
      <li
        v-for="item in list"
        :key="item.name"
      >
        <button
          :class="{ active: selected === item.name }"
          @click="selectDistrict(item.name)"
        >{{item.name}}</button>
      </li>
    </ul>
    <!-- 查看更多按钮 -->
    <div
      class="view-more"
      :class="{ open: allDisplayed }"
      v-if="hasMore"
      @click="clickDisplayed"
    >{{allDisplayed ? '收起' : '其他地区'}}</div>
  </div>
</template>

<script>

export default {
  name: 'BubbleDistrict',
  props: {
    message: Object
  },
  data () {
    return {
      selected: '',
      defaultCount: (4 * 4),
      allDisplayed: false
    }
  },
  computed: {
    hasMore () {
      return this.message.content.length > this.defaultCount
    },
    list () {
      if (this.allDisplayed) {
        return this.message.content
      } else {
        return this.message.content.slice(0, this.defaultCount)
      }
    }
  },
  methods: {
    clickDisplayed () {
      this.allDisplayed = !this.allDisplayed
      this.$store.commit('updateFreshNeed', true)
    },
    selectDistrict (name) {
      if (this.selected) {
        return false
      }

      this.selected = name
      // 发送消息
      this.$store.dispatch('askQuestion', {
        from: 2,
        question: name,
        dataType: 'district-selected',
        onlyRobot: true
      })
    }
  }
}
</script>
